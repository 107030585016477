import { PageProps } from 'gatsby'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import Nav from './components/Nav'

const Wrapper = styled.div`

`

const Layout = (props: PropsWithChildren<PageProps | any>) => {
  return <Wrapper>
    <Nav {...props} />
    {props.children}
  </Wrapper>
}

export default Layout
