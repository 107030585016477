import { gql, useQuery } from '@apollo/client'
import { Link, navigate, PageProps } from 'gatsby'
import { ArrowLeft, Avatar, Button } from 'nzk-react-components'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  color: #fff;
  padding: 0px 20px;
  
  a {
    color: #fff;
    text-decoration: none;
  }
  background-color: #341644;
`

const Child = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.7em;
  padding: 10px;
  cursor: pointer;
  ${props => props.selected && css`
    background-color: rgba(255, 255, 255,  0.05);
    color: #FFED00;
  `}
  font-family: 'Rammetto One';
  font-size: 10px;
`


const BackButton = styled.div`
  align-self: center;
`

const ChildrenList = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-right: 59px;
`

const Nav = (props: PageProps) => {
  const { data } = useQuery(gql`
    query getChildren {
      me {
        _id
        children {
          _id
          username
          name
          avatar {
            url
          }
          starTutoring {
            _id
            status
            willCancelAt
          }
        }
      }
    }
  `)

  const activeChildren = useMemo(() => {
    return (data?.me?.children || []).filter(child => {
      return child.starTutoring
        && child.starTutoring.status !== 'cancelled'
        && (!child.starTutoring.willCancelAt || new Date(child.starTutoring.willCancelAt) > new Date())
    })
  }, [data])

  
  return <Wrapper>
    <BackButton>
      <Button theme='primary' size='regular' round onClick={() => navigate('/advanced-tutoring')}>
        <ArrowLeft />
      </Button>
    </BackButton>
    <ChildrenList>
    {
      activeChildren.map(c => {
        const selected = props.params?.username === c.username
        return <Link key={c._id} to={selected ? `/advanced-tutoring` : `/advanced-tutoring/${c.username}`}>
          <Child selected={selected}>
            <Avatar user={c} flagHidden colors={{
              border: '#10BBEA',
              shadow: '#025899',
              dropshadow: 'rgba(0,0,0,0.4)'
            }} />
            { c.name && <div>{c.name}</div> }
            <div>{c.username}</div>
          </Child>
        </Link>
      })
    }
    </ChildrenList>
  </Wrapper>
}

export default Nav
