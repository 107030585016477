import { useApolloClient } from '@apollo/client'
import { Button, Checkmark, Input, Pencil } from 'nzk-react-components'
import { useLocation } from '@reach/router'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import GET_PROMOTION_CODE from './getPromotionCode.graphql'

export interface IPromotionCode {
  id: string
  active: boolean
  coupon: {
    percent_off: number
    amount_off: number
    valid: boolean
    duration: string
		duration_in_months: any
  }
}

interface IProps {
  onChange?: (value: IPromotionCode | null) => void
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .coupon-input {
    display: flex;
    align-items: center;
    > :first-child {
      margin-right: 10px;
    }
  }
  .coupon {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px 12px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    > * { margin-right: 12px; }
    > :last-child { margin-right: 0; }
  }
`
const ErrorMessage = styled.div`
  margin-bottom: 8px;
  color: red;
  font-size: 15px;
`

const PromotionCode = (props: IProps) => {
  const client = useApolloClient()
  const location = useLocation()
  const presetCode = new URLSearchParams(location.search).get('code')

  const [code, setCode] = useState('')
  const [promotionCode, setPromotionCode] = useState<IPromotionCode | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  
  const checkCode = async () => {
    if (!code || loading) return
    setError(null)
    setLoading(true)
    const { data } = await client.query({
      query: GET_PROMOTION_CODE,
      variables: {
        code
      }
    })
    if (data?.promotionCode) {
      if (data?.promotionCode.active) {
        setPromotionCode(data.promotionCode)
      } else {
        setError('This code doesn\'t exist or has expired.')
      }
    } else {
      setError('This code doesn\'t exist or has expired.')
    }
    setLoading(false)
  }

  useEffect(() => {
    const code = new URLSearchParams(location.search).get('code')
    if (code) {
      setCode(code)
    }
  }, [])

  useEffect(() => {
    if (code && presetCode) {
      checkCode()
    }
  }, [code, presetCode])

  useEffect(() => {
    if (props.onChange) {
      props.onChange(promotionCode)
    }
  }, [promotionCode])

  return <Wrapper>
    { error && <ErrorMessage>{error}</ErrorMessage> }
    { !promotionCode && <div className='coupon-input'>
      <Input placeholder='Code' value={code} onChange={(e) => setCode(e.target.value)} />
      <Button size='x-small' round theme='confirm' onClick={checkCode} disabled={!code || loading}>
        <Checkmark />
      </Button>
    </div> }
    { promotionCode && <div className='coupon'>
      {promotionCode.coupon.percent_off && <div>{promotionCode.coupon.percent_off}% OFF</div>}
      {promotionCode.coupon.duration === 'forever' && <div>FOREVER</div> }
      {promotionCode.coupon.duration_in_months && <div>{promotionCode.coupon.duration_in_months} months</div>}
      <Button size='x-small' round theme='orange' onClick={() => setPromotionCode(null)}>
        <Pencil />
      </Button>
    </div> }
  </Wrapper>
}

export default PromotionCode
