import StarTutoringSubscribe from 'components/pages/StarTutoring/pages/StarTutoringSubscribe'
import { PageProps } from 'gatsby'
import React from 'react'
import Layout from '../../../layouts/layout'

export default (props: PageProps) => {
  if (typeof window === "undefined") return null
  return <Layout>
    <StarTutoringSubscribe {...props} username={props.params.username} />
  </Layout>
}
