import { gql, useMutation, useQuery } from '@apollo/client'
import { getFormattedAmount } from 'components/pages/SubscribePage/utils'
import { Link, navigate, PageProps } from 'gatsby'
import { Avatar, Button } from 'nzk-react-components'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import 'twin.macro'
import PromotionCode, { IPromotionCode } from '../components/PromotionCode'
import Layout from '../layout'

const Wrapper = styled.div`
  color: #fff;
  padding: 30px 15px 50px 15px;
  h3 {
    font-size: 16px;
    text-align: center;
    font-family: 'Rammetto One';
    font-weight: 400;
  }
  p {
    max-width: 640px;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    line-height: 2;
  }
  .info {
    line-height: 17px;
    margin-top: 6px;
    font-size: 13px;
    margin-bottom: 10px;
  }
`

const User = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Rammetto One';
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 30px;
`

const Plans = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  flex-wrap: wrap;
  > * {
    margin-right: 10px;
    margin-bottom: 25px;
  }
  > :last-child {
    margin-right: 0;
  }
`

const Title = styled.div`
  font-size: 30px;
  line-height: 36px;
  font-family: 'Rammetto One';
  font-weight: 400;
  margin-bottom: 28px;
  text-shadow: 0px 4px #afafaf, 0px 8px rgba(0,0,0,0.4);
  text-align: center;
`

const Plan = styled.div<{ selected: boolean }>`
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #fff;
  cursor: pointer;
  min-width: 223px;
  padding: 13px 30px;

  > .title {
    font-family: 'Rammetto One', cursive;
    font-weight: 400;
    font-size: 30px;
    line-height: 51px;
  }

  > .price {
    font-size: 30px;
    line-height: 30px;
  }

  > .trial {
    font-size: 12px;
    color: #D67CFF;
  }

  > .actions {
    margin-top: 15px;
  }

  ${props => props.selected
  ? css`
    background-color: #8CC63F;
    box-shadow: 0 6px 0 #579820, 0 10px 0 rgba(0,0,0,0.4);
    > .trial {
      font-size: 12px;
      color: #FFF;
    }
  `
  : css`
    background-color: #6F1EA9;
    box-shadow: 0 6px 0 #3E006A, 0 10px 0 rgba(0,0,0,0.4);
  `}
`

const Actions = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`

const Coupon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 30px;
  > .title {
    margin-bottom: -3px;
  }
  
  input {
    color: #000;
  }
`

const ErrorMessage = styled.div`
  margin-top: 20px;
  text-align: center;
  color: #f00;
  padding: 5px 12px;
`

const ExtraSupport = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 640px;
  margin: 0 auto;
  > * { margin-bottom: 15px; }
  > :last-child { margin-bottom: 0; }
  margin-bottom: 20px;
  textarea {
    color: #000;
    resize: none;
    font-size: 21px;
    border: none;
    font-family: 'Libre Baskerville';
    box-shadow: 0 4px 0 rgba(0,0,0,0.4);
    width: 100%;
    border-radius: 10px;
    padding: 10px 20px;
    min-height: 80px;
    :disabled {
      background-color: #6F1EA9;
      color: #fff;
    }
  }
`

interface IProps extends PageProps {
  username: string
}

const StarTutoringSuscribe = (props: IProps) => {
  const [selectedPlan, selectPlan] = useState<any | null>(null)
  const [promotionCode, setPromotionCode] = useState<IPromotionCode | null>(null)
  const [message, setMessage] = useState('')
  const couponSectionRef = useRef<HTMLDivElement | null>(null)

  const { data } = useQuery(gql`
    query getAvailableStarTutoringPlans($username: String) {
      findUser(input: { username: $username }) {
        _id
        username
        name
        avatar {
          url
        }
        starTutoring {
          _id
          status
        }
      }
      me {
        _id
        subscription {
          id
          customer {
            id
            card {
              id
              exp_year
              exp_month
              last4
            }
          }
        }
        starTutoringPlans {
          id
          unit_amount
          currency
          recurring {
            interval
            interval_count
            trial_period_days
          }
        }
      }
    }
  `, {
    variables: {
      username: props.username
    },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (selectedPlan && couponSectionRef.current && typeof window !== 'undefined') {
      window.scrollTo({ top: couponSectionRef.current.offsetTop, behavior: 'smooth' })
    }
  }, [selectedPlan])

  const [subscribeMutation, { loading: subscribing, error: subscribingError }] = useMutation(gql`
    mutation subscribeStarTutoring($zookeeperId: String!, $stripePrice: String!, $message: String, $promotionCode: String) {
      starTutoring_subscribe(input: {
        zookeeperId: $zookeeperId,
        stripePrice: $stripePrice,
        message: $message,
        promotionCode: $promotionCode,
      }) {
        _id
        starTutoring {
          _id
          subscriptionId
          subscriptionExpiresAt
          status
          message
          nextAssignmentDate
        }
      }
    }
  `)

  const subscribe = async (plan: any) => {
    const { errors } = await subscribeMutation({
      variables: {
        zookeeperId: data?.findUser?._id,
        stripePrice: plan.id,
        promotionCode: promotionCode?.id,
        message
      },
      errorPolicy: 'all'
    })
    if (!errors || errors.length === 0) {
      navigate(`/advanced-tutoring/${props.username}`)
    }
  }

  const onSubscribe = (plan: any) => {
    if (!data?.findUser) return null
    subscribe(plan)
  }

  const plans = useMemo(() => {
    return (data?.me?.starTutoringPlans || []).map(plan => {
      let term
      let appendPrice
      const price = Intl.NumberFormat('en-us', { style: 'currency', currency: plan.currency })
      .format(getFormattedAmount(plan.unit_amount, plan.currency))

      const recurring = plan.recurring
      if (recurring?.interval === 'year') {
        term = 'Yearly'
        appendPrice = ` / year`
      } else if (recurring?.interval === 'month' && recurring?.interval_count === 1) {
        term = 'Monthly'
        appendPrice = ` / month`
      } else if (recurring?.interval === 'week' && recurring?.interval_count === 1) {
        term = 'Weekly'
        appendPrice = ` / week`
      }
      const priceLabel = `${price}${appendPrice}`

      let discountPriceLabel
      if (promotionCode) {
        const discountPrice = Intl.NumberFormat('en-us', { style: 'currency', currency: plan.currency })
        .format(getFormattedAmount(plan.unit_amount - (plan.unit_amount * ((promotionCode.coupon.percent_off || 0) / 100) - promotionCode.coupon.amount_off), plan.currency))
        discountPriceLabel = `${discountPrice}${appendPrice}`
      }
    
      return {
        ...plan,
        term,
        discountPriceLabel,
        priceLabel
      }
    })
  }, [data, promotionCode])

  useEffect(() => {
    if (data?.findUser?.starTutoring?.status === 'active') {
      // navigate(`/advanced-tutoring/${props.username}`)
    }
  }, [data])
  

  return <Layout {...props}>
    <Wrapper>
      <Title>Advanced Tutoring</Title>
      { data && <User>
        <Avatar user={data.findUser} size='110px' />
        <div>{data.findUser?.username}</div>
        <div>{data.findUser?.name}</div>
      </User> }
      {
        data && !data.me.subscription?.customer?.card && <div tw='flex justify-center'>
          <div tw='bg-[#701EA8] text-white px-6 py-6 rounded-xl text-center'>
            <div tw='text-xl mb-3 font-bold'>No card is registered on your account.</div>
            <div>Please add one from <Link to='/account'><u>your account settings</u></Link></div>
          </div>
        </div>
      }
      { data && data.me.subscription?.customer?.card && <>
        <Coupon ref={couponSectionRef}>
          <h3 className='title'>Do you have an Advanced Tutoring discount code?</h3>
          <p className='info'>Regular discount codes for nightzookeeper.com cannot be applied.</p>
          <PromotionCode onChange={(promoCode) => setPromotionCode(promoCode)} />
        </Coupon>
        <h3 style={{ marginBottom: '-2px' }}>Select a plan</h3>
        <Plans>
          {
            plans.map(plan => {
              const selected = plan.id === selectedPlan?.id
              return <Plan key={plan.id} selected={selected} onClick={() => !selected ? selectPlan(plan) : () => {}}>
                <div className='title'>{plan.term}</div>
                <div tw="text-yellow-300 text-sm mb-4">Turn off anytime</div>
                <div className='price' style={{
                  textDecoration: plan.discountPriceLabel ? 'line-through' : 'none',
                  fontSize: plan.discountPriceLabel ? '1.4em' : '',
                }}>
                  { plan.priceLabel }
                </div>
                { plan.discountPriceLabel && <div className='price' tw='mt-3'>
                  { plan.discountPriceLabel }
                </div> }
                <div className='actions'>
                { plan.recurring.trial_period_days > 0
                  ? <Button
                    size='x-small'
                    theme='primary'
                    disabled={selected}
                    
                  >{ selected ? 'Selected' : `Start a ${plan.recurring.trial_period_days}-day free trial`}</Button>
                  : <Button
                    size='x-small'
                    theme='primary'
                    disabled={selected}
                  >{ selected ? 'Selected' : 'Select'}</Button>
                }
                </div>
                { plan.recurring.trial_period_days > 0
                  ?  <div className='trial'>
                    { plan.recurring.trial_period_days } days free, then {plan.discountPriceLabel || plan.priceLabel}.
                  </div>
                  : <div className='trial'>
                    Payment taken immediately
                  </div> }
              </Plan>
            })
          }
        </Plans>
        <ExtraSupport>
          <h3>Where does your child need extra support?</h3>
          <textarea value={message} placeholder='Message for your child tutor...' onChange={(e) => setMessage(e.target.value)} />
        </ExtraSupport>
        { subscribingError && <ErrorMessage>{subscribingError?.message}</ErrorMessage> }
        <Actions>
          <Button
            size='regular'
            theme='primary'
            disabled={!selectedPlan || subscribing}
            onClick={() => selectedPlan && !subscribing && onSubscribe(selectedPlan)}
          >{subscribing ? 'Loading...' : 'Start Now'}</Button>
        </Actions>
        <p className='info'>Turn off anytime in your dashboard</p>
      </> }
    </Wrapper>
  </Layout>
}

export default StarTutoringSuscribe
